<!--
 * @Description: 停车场运营管理系统 出入口管理 inletOutletManage
 * @Author: yanxiao
 * @Github: https://github.com/hzylyh
 * @Date: 2021-03-16 19:29:32
 * @LastEditors: zhoucheng
-->
<template>
  <div class="order">
    <el-scrollbar style="height:100%">
      <el-row class="topSelectTwoLine">
        <el-form :inline="true"
                 :model="searchForm">
          <el-row class="topFirstLine">
            <el-col :span="6">
              <el-form-item label-width="90px"
                            label="停车场名称">
                <el-select class="search-input"
                           clearable
                           v-model="searchForm.parkId"
                           placeholder="全部">
                  <el-option v-for="(item, index) in parkNameList"
                             :key="index"
                             :label="item.name"
                             :value="item.code">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="topButton">
            <el-button type="primary"
                       @click="handleClickSearch">查 询</el-button>
            <el-button type="info"
                       style="margin-left:17px"
                       @click="resetForm">重 置</el-button>
          </el-row>
        </el-form>
      </el-row>
      <div class="tableRow">
        <el-row class="tableTitle">
          <span>出入口管理</span>
          <el-row class="topButton">
            <el-button type="danger"
                       @click="handleOrderDel">
              批量删除
            </el-button>
          </el-row>
        </el-row>
        <el-row class="tableContent">
          <el-table ref="park"
                    size="small"
                    :data="parkList"
                    style="width: 100%"
                    row-key="parkId"
                    lazy
                    v-loading="parkListLoading"
                    border
                    :row-class-name="tableRowClassName">
            <el-table-column prop="inOutLinst"
                             width="50"
                             type="expand">
              <template slot-scope="props">
                <el-table :data="inOutList[props.row.parkId]"
                          @selection-change="handleSelectionChange"
                          class="table-child"
                          :row-class-name="tableRowClassName">
                  <el-table-column type="selection"
                                   width="50"></el-table-column>
                  <el-table-column prop="passagewayTypeName"
                                   label="出入口类型"
                                   width="300">
                  </el-table-column>
                  <el-table-column prop="passagewayName"
                                   label="车出入口名称"
                                   width="300">
                  </el-table-column>
                  <el-table-column prop="passagewayDesc"
                                   label="描述"
                                   width="400">
                  </el-table-column>
                  <el-table-column label="操作">
                    <template slot-scope="scope">
                      <el-button type="text"
                                 size="medium"
                                 @click="handleOrderUpdate(scope.$index, scope.row)">
                        修改
                      </el-button>
                      <el-button type="text"
                                 size="medium"
                                 @click="handleOrderDelete(scope.$index, scope.row)">
                        删除
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </el-table-column>
            <el-table-column prop="parkName"
                             label="停车场名称"
                             width="450px"></el-table-column>
            <el-table-column show-overflow-tooltip
                             prop="address"
                             label="停车场地址"></el-table-column>
            <el-table-column show-overflow-tooltip
                             prop="remark"
                             label="停车场备注"></el-table-column>
            <el-table-column show-overflow-tooltip
                             prop="operation"
                             label="操作">
              <template slot-scope="scope">
                <el-button type="text"
                           size="medium"
                           @click="handleAddInOut(scope.$index, scope.row)">
                  新增出入口
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <el-row style="bottom: 20px"
                justify="space-between">
          <el-col :span="4"
                  class="pageLeft">
            共{{this.total}}条
          </el-col>
          <el-col :span="20">
            <el-pagination class="page"
                           layout="prev,sizes,pager,next,jumper"
                           background
                           @current-change="getList"
                           @size-change="sizeChange"
                           :current-page="pageNum"
                           :page-size="pageSize"
                           :page-sizes=[15,50,200]
                           :total="total">
            </el-pagination>
          </el-col>
        </el-row>
      </div>
    </el-scrollbar>

    <!-- 订单弹窗 添加 修改 查看共用此弹窗 根据orderCRUD.value判断类型 0 不显示，1 创建，2 查看，3 更新，4 请求中 -->
    <el-dialog append-to-body
               @close="popClose"
               :visible.sync="orderVisible"
               :title="orderCRUD.title">
      <el-form ref="inOutForm"
               :model="inOutForm"
               size="small"
               label-width="110px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="出入口类型"
                          prop="passagewayTypeCode">
              <el-select class="form-input"
                         style="width:63%"
                         v-model="inOutForm.passagewayTypeCode"
                         filterable
                         placeholder="请选择">
                <el-option v-for="(item) in inOutTypeList"
                           :key="item.value"
                           :label="item.text"
                           :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="出入口名称"
                          prop="passagewayName">
              <el-input class="form-input"
                        style="width:63%"
                        v-model="inOutForm.passagewayName" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="描述"
                          prop="passagewayDesc">
              <el-input class="form-input"
                        style="width:63%"
                        v-model="inOutForm.passagewayDesc" />
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
      <span slot="footer"
            class="dt-button-box">
        <el-button :loading="orderCRUD.value == 4"
                   type="success"
                   @click="confirmOrderOperate()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  name: 'park',
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    //  这里存放数据
    return {
      pageNum: 1,
      pageSize: 15,
      total: 0,// 分页参数
      searchForm: {
        parkTypeCode: '2'
      },// 查询条件
      parkListLoading: false,     // 列表加载中
      parkNameList: [], // 停车场列表
      parkList: [],
      parkIds: [],// 停车场id列表
      inOutList: {},  // 出入口列表
      // 表格多选按钮列表
      tabSelectedList: [],
      //   弹出框表单
      inOutForm: {
        passagewayTypeCode: '1',
        passagewayName: '',
        passagewayDesc: '',
      },
      // 订单弹窗  新增 0, 修改1
      orderCRUD: {
        title: '',
        value: null,
        parkId: '',
      },
      orderVisible: false,// 修改弹框是否弹出
      // 出入口类型
      inOutTypeList: [
        {
          text: '出口',
          value: '1',
        },
        {
          text: '入口',
          value: '2',
        },
      ],
      collapseFlag: true,// 展开 收起标志位
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.queryParkList()
    this.getParkData()
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
  //方法集合
  methods: {
    // 查询停车场列表
    getParkData () {
      this.searchForm["pageNum"] = this.pageNum
      this.searchForm["pageSize"] = this.pageSize
      this.$yardManagementApi.ParkController(this.searchForm).then((response) => {
        this.total = response.resultEntity.total
        let list = response.resultEntity.list
        this.parkIds = []
        list.forEach((item) => { this.parkIds.push(item.parkId) })
        this.parkList = list
        this.getInOutData()
      })
    },
    // 分页
    getList (change) {
      this.pageNum = change
      this.getParkData()
    },
    sizeChange (change) {
      this.pageSize = change
      this.pageNum = 1
      this.getParkData()
    },
    // 查询按钮
    handleClickSearch () {
      this.pageNum = 1
      this.getParkData()
    },
    // 重置按钮
    resetForm () {
      this.searchForm = {}
      this.pageNum = 1
      this.getParkData()
    },
    // 查询出入口
    getInOutData () {
      this.parkIds.forEach((item) => {
        let info = {
          parkId: item,
        }
        this.$inOutManage.queryInOut(info).then((res) => {
          let list = res.resultEntity
          this.inOutList[item] = list
        })
      })
    },

    // 批量删除
    handleOrderDel () {
      if (this.tabSelectedList.length == 0) {
        this.$message({ message: '请先选择出入口', type: 'warning', })
        return
      }
      this.$confirm('确认删除出入口?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        let info = []
        this.tabSelectedList.forEach((item) => {
          info.push(item.passagewayId)
        })
        this.$inOutManage.deleteListInOut(this.tabSelectedList).then(() => {
          this.getParkData()
        })
      })
    },
    // 表格多选按钮
    handleSelectionChange (val) {
      this.tabSelectedList = val
    },
    //新增出入口
    handleAddInOut (index, row) {
      this.orderVisible = true
      this.orderCRUD = { title: '新增出入口', value: 0, parkId: row.parkId, }
    },
    // 修改出入口
    handleOrderUpdate (index, row) {
      console.log("修改信息", row);
      for (let key in row) {
        this.inOutForm[key] = row[key]
      }
      this.orderCRUD = { title: '修改出入口', value: 1, parkId: row.parkId, }
      this.orderVisible = true
    },
    // 删除出入口
    handleOrderDelete (index, row) {
      this.$confirm('确认删除出入口?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        let info = [row]
        this.$inOutManage.deleteListInOut(info).then(() => {
          this.$message({ message: '删除成功', type: 'success' });
          this.$forceUpdate()
        })
      })
    },

    // 弹出关闭事件
    popClose () {
      this.orderCRUD = {
        title: '',
        value: null,
        parkId: '',
      }
      this.inOutForm = {
        passagewayTypeCode: '1',
        passagewayName: '',
        passagewayDesc: '',
      }
    },
    // 弹出层确认按钮
    confirmOrderOperate () {
      if (this.orderCRUD.value === 0) {
        let info = {
          passagewayTypeCode: this.inOutForm.passagewayTypeCode,
          passagewayName: this.inOutForm.passagewayName,
          passagewayDesc: this.inOutForm.passagewayDesc,
          parkId: this.orderCRUD.parkId,
          creater: '',
        }
        this.$inOutManage.insertInOut(info).then(() => {
          this.$message({ message: '新增成功', type: 'success' });
          this.orderVisible = false
          this.$forceUpdate()

        })
      } else if (this.orderCRUD.value === 1) {
        this.$inOutManage.updateInOut(this.inOutForm).then(() => {
          this.$message({ message: '修改成功', type: 'success' });
          this.orderVisible = false
          this.$forceUpdate()

        })
      } else {
        console.error('orderCRUD值错误')
      }
      this.getParkData()
    },
    // 获取停车场名称
    queryParkList () {
      let info = {
        columnName: ['park_id', 'park_name'], //有且只有两个 id放前面名称放后面
        tableName: 'tb_park',
        "whereStr": [
          {
            "colName": "park_type_code",
            "value": '2'
          }
        ]
      }
      this.$queryDict.queryDictWithAuth(info).then((response) => {
        this.parkNameList = response.resultEntity
      })
    },
    // 斑马线
    tableRowClassName ({ row, rowIndex }) {
      row
      if (rowIndex % 2 === 0) {
        return "double-row";
      } else if (rowIndex % 2 === 1) {
        return "odd-row";
      }
      return ''
    },
  },
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
$topSelectHeight: 135px;

.dt-button-box {
  display: flex;
  justify-content: flex-end;
}
.order {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  .topSelectTwoLine {
    width: 100%;
    height: $topSelectHeight;
    @include background('blockBg');
    padding-top: 24.3px;
    padding-left: 21px;
    .topButton {
      display: flex;
      padding-left: 91px;
      margin-top: 17px;
    }
  }
  .tableRow {
    width: 98%;
    @include background('blockBg');
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      padding-top: 15px;
      .topButton {
        display: flex;
        padding-left: 91x;
        float: right;
      }
    }
    .tableContent {
      margin-top: 25px;
      margin-bottom: 30px;
    }
  }
}

//分页
.page {
  float: right;
  color: #70aee8;
}
//分页
.pageLeft {
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: bold;
  @include font_color('paginationFontColor');
}

//分页总条数
.totalNumber {
  color: #70aee8;
  font-size: 0.75rem;
  line-height: 1.06rem;
}

/deep/.btn-prev {
  background-color: #051835 !important;
  // margin-right: 1.33rem !important;
  margin-left: 0rem !important;
}
/deep/.btn-next {
  background-color: #051835 !important;
  // margin-left: 1.33rem !important;
  margin-right: 0rem !important;
}
/deep/ .number {
  background-color: #051835 !important;
  color: #70aee8 !important;
  border: 0.08rem solid #004474;
}
//按钮选中
/deep/ .el-pager li.active {
  border: 0.08rem solid #00aec3;
}
//页码按钮边距
/deep/ .el-pager li {
  margin: 0rem !important;
  min-width: 1.63rem;
}
//选中的按钮
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: rgba(255, 255, 255, 0);
}

/deep/ li.el-icon.more.btn-quickprev.el-icon-more {
  background-color: #051835 !important;
  color: #70aee8 !important;
  border: 0.08rem solid #004474;
}
/deep/li.el-icon.more.btn-quicknext.el-icon-more {
  background-color: #051835 !important;
  color: #70aee8 !important;
  border: 0.08rem solid #004474;
}

.Pager {
  // /deep/.el-pagination {
  //   padding: 0rem;
  // }
  // /deep/.order[data-v-6b05d2c1] .page {
  //   margin: 0rem;
  // }
  // .page .el-pagination .is-background {
  //   margin: 0rem;
  // }
}
.form-input {
  width: 188px;
}

/deep/.el-scrollbar__view {
  height: 100%;
  width: 100%;
}
/deep/.el-table__expanded-cell {
  background: #00305a;
  padding: 0;
}
/deep/.el-table__body-wrapper.is-scrolling-none:hover {
  background: #00305a;
}
/deep/ .el-table .el-table__expand-icon .el-icon-arrow-right:before {
  content: '\e6d9';
  background: #081b38;
  border: 1px solid #00487a;
  color: #00ebfb;
}
/deep/ .el-table .el-table__expand-icon--expanded {
  transform: rotate(0);
  .el-icon-arrow-right:before {
    content: '\e6d8';
    background: #081b38;
    border: 1px solid #00487a;
    color: #00ebfb;
  }
}
.el-input--medium .el-input__icon {
  line-height: 45px;
}
</style>